import styled from 'styled-components'

// Kha: Please consider display: grid. Reason: easier to responsive. 
export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 0.7rem;
  height:100vh;
  position: sticky;
  top: 0;
  right: 0;

  @media all and (max-width : 600px){
    display: none;
  }
`
export const StyledColumn = styled.div`
  background: no-repeat center url(${props => props.image});
  background-size: cover;

`
