import { createGlobalStyle } from 'styled-components'
import { darken, rgba } from 'polished'

export const GlobalStyle = createGlobalStyle`
  ::selection {
    color: ${props => props.theme.WHITE};
    background: ${props => props.theme.PRIMARY_COLOR};
  }

  a {
    color: ${props => props.theme.PRIMARY_COLOR};

    &:hover {
      color: ${props => darken(0.05, props.theme.PRIMARY_COLOR)};
    }
  }

  .ant-input {
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-radius: 0;

    &:hover {
      border-bottom-color: ${props => props.theme.PRIMARY_COLOR};
    }
  }

  .ant-input:focus, .ant-input-focused {
    border-bottom-color: ${props => props.theme.PRIMARY_COLOR};
    box-shadow: 0 1px 0 0 ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-btn:hover, .ant-btn:focus {
    color: ${props => props.theme.PRIMARY_COLOR};
    border-color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-btn-primary:not([disabled]) {
    background-color: ${props => props.theme.PRIMARY_COLOR};
    border-color: ${props => props.theme.PRIMARY_COLOR};

    &:hover {
      color: ${props => props.theme.WHITE};
      background-color: ${props => darken(0.05, props.theme.PRIMARY_COLOR)};
      border-color: ${props => darken(0.05, props.theme.PRIMARY_COLOR)};
    }

    &:focus {
      color: ${props => props.theme.WHITE};
      background-color: ${props => darken(0.1, props.theme.PRIMARY_COLOR)};
      border-color: ${props => darken(0.1, props.theme.PRIMARY_COLOR)};
    }

    &:active {
      color: ${props => props.theme.WHITE};
      background-color: ${props => darken(0.15, props.theme.PRIMARY_COLOR)};
      border-color: ${props => darken(0.15, props.theme.PRIMARY_COLOR)};
    }
  }

  .ant-btn-danger {
    background-color: #ff4d4f;
    border-color: #ff4d4f;

    &:hover {
      color: ${props => props.theme.WHITE};
      background-color: ${darken(0.05, '#ff4d4f')};
      border-color: ${darken(0.05, '#ff4d4f')};
    }

    &:focus {
      color: ${props => props.theme.WHITE};
      background-color: ${darken(0.1, '#ff4d4f')};
      border-color: ${darken(0.1, '#ff4d4f')};
    }

    &:active {
      color: ${props => props.theme.WHITE};
      background-color: ${darken(0.15, '#ff4d4f')};
      border-color: ${darken(0.15, '#ff4d4f')};
    }
  }

  .ant-btn-dangerous.ant-btn-primary {
    background-color: #ff4d4f;
    border-color: #ff4d4f;

    &:hover {
      color: ${props => props.theme.WHITE};
      background-color: ${darken(0.05, '#ff4d4f')};
      border-color: ${darken(0.05, '#ff4d4f')};
    }

    &:focus {
      color: ${props => props.theme.WHITE};
      background-color: ${darken(0.1, '#ff4d4f')};
      border-color: ${darken(0.1, '#ff4d4f')};
    }

    &:active {
      color: ${props => props.theme.WHITE};
      background-color: ${darken(0.15, '#ff4d4f')};
      border-color: ${darken(0.15, '#ff4d4f')};
    }
  }

  .ant-btn-link {
    color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-btn-link:hover, .ant-btn-link:focus, .ant-btn-link:active {
    border-color: transparent;
  }

  .ant-radio-button-wrapper:hover {
    color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: ${props => props.theme.PRIMARY_COLOR};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${props => props.theme.PRIMARY_COLOR};
    border-color: ${props => props.theme.PRIMARY_COLOR};

    &::before {
      background-color: ${props => props.theme.PRIMARY_COLOR};
    }

    &:first-child {
      border-color: ${props => props.theme.PRIMARY_COLOR};
    }

    &:hover {
      color: ${props => props.theme.PRIMARY_COLOR};
      border-color: ${props => props.theme.PRIMARY_COLOR};

      &::before {
        background-color: ${props => props.theme.PRIMARY_COLOR};
      }
    }

    &:focus-within {
      box-shadow: 0 0 0 0.25rem ${props =>
        rgba(props.theme.PRIMARY_COLOR, 0.08)};
    }
  }
`
