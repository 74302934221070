import React from 'react'
import { AntButton } from './styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const StartButton = ({ survey }) => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <AntButton
      type='primary'
      onClick={() => history.push(`/survey/${survey.id}/product`)}
    >
      {t(`components.${process.env.REACT_APP_THEME}.button.start`)}
    </AntButton>
  )
}
export default StartButton
