import styled from 'styled-components'

export const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
`

export const Image = styled.img`
  width: 25%;
`
export const Text = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 18px 0 30px;
  margin-bottom: 50px;
`
export const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
`
