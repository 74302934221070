import React from 'react'
import { Primary, Default } from './styles'
import CancelButton from './CancelButton'
import { SubmitButton, SubmitButtonMobile } from './SubmitButton'
import BackButton from './BackButton'
import SidebarToggle from './SiderbarToggle'
import StartButton from './StartButton'

const PrimaryButton = ({
  handleClick,
  text,
  loading = false,
  disabled = false,
  confirmation = false
}) => {
  return (
    <React.Fragment>
      <Primary
        type='primary'
        onClick={handleClick}
        disabled={disabled}
        loading={loading}
        confirmation={confirmation}
      >
        {text}
      </Primary>
    </React.Fragment>
  )
}
const DefaultButton = ({
  handleClick,
  text,
  loading = false,
  disabled = false,
  confirmation = false
}) => {
  return (
    <React.Fragment>
      <Default
        onClick={handleClick}
        disabled={disabled}
        loading={loading}
        confirmation={confirmation}
      >
        {text}
      </Default>
    </React.Fragment>
  )
}

export {
  PrimaryButton,
  DefaultButton,
  SidebarToggle,
  StartButton,
  CancelButton,
  SubmitButton,
  BackButton,
  SubmitButtonMobile
}
