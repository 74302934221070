import React from 'react'
import { Screen, Image, Text, Container, Content } from './styles'
import { PrimaryButton } from '../Buttons'
import { useHistory } from 'react-router-dom'
import image from '@/assets/images/logo_lg.png'
import ImageGroup from '../ImageGroup'

const Error404 = () => {
  return (
    <Screen>
      <Image src={image} alt='Logo' />
      <Text>oops! not Found 404</Text>
    </Screen>
  )
}

const InValidSurvey = () => {
  return (
    <Container>
      <Content>
        <Image src={image} alt='Logo' />
        <Text>Please Select a valid survey</Text>
      </Content>
      <div>
        <ImageGroup />
      </div>
    </Container>
  )
}

const BuildingPage = () => {
  const history = useHistory()
  return (
    <Screen>
      <Image src={image} alt='Building Page' />
      <Text>Under Development...</Text>
      <PrimaryButton
        handleClick={() => {
          history.goBack()
        }}
        text={'Back'}
      />
    </Screen>
  )
}

export { Error404, InValidSurvey, BuildingPage }
