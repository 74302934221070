import { createSlice } from '@reduxjs/toolkit'

// Creating a user slice
const userSlice = createSlice({
  name: 'users',
  initialState: {
    usersList: [],
    loggedIn: false
  },
  reducers: {
    addUser: (state, action) => {
      state.loggedIn = action.payload
    }
  }
})

//// * * * Creating a user actions * * *
export const { addUser } = userSlice.actions

export default userSlice.reducer


