import React from 'react'
import { StyledButton, Text, Icon } from './styles'
import { MenuOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const SidebarToggle = ({ toggle, setToggle, showPopup }) => {
  const { unanswered } = useSelector(state => state.answer)

  const { t } = useTranslation();
  return (
    <StyledButton onClick={() => unanswered ? showPopup(true) : setToggle(!toggle) }>
      <Icon>
        <MenuOutlined />
      </Icon>
      <Text>{t(`components.${process.env.REACT_APP_THEME}.button.menu`)}</Text>
    </StyledButton>
  )
}
export default SidebarToggle
