import React from 'react'
import { StyledButton, Text, GifImage } from './styles'
import Gif from '@/assets/images/Check.gif'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SubmitButton = ({ showPopup }) => {
  const { t } = useTranslation()
  const { surveyId } = useParams()
  const { answered, unanswered, answerLoading } = useSelector(
    state => state.answer
  )

  return (
    <Link
      to={!unanswered ? `/survey/${surveyId}/review` : '#'}
      onClick={() => {
        unanswered && showPopup(true)
      }}
    >
      <StyledButton type='primary' disabled={!answered.length || answerLoading}>
        <Text>
          {t(`components.${process.env.REACT_APP_THEME}.button.submit`)}
        </Text>
        {answered.length && process.env.REACT_APP_THEME === 'bunge' ? (
          <GifImage src={Gif} />
        ) : null}
      </StyledButton>
    </Link>
  )
}

const SubmitButtonMobile = ({ showPopup }) => {
  const { t } = useTranslation()
  const { surveyId } = useParams()
  const { answered, unanswered, answerLoading } = useSelector(
    state => state.answer
  )
  return (
    <Link
      to={!unanswered ? `/survey/${surveyId}/review` : '#'}
      onClick={() => {
        unanswered && showPopup(true)
      }}
    >
      <StyledButton
        type='primary'
        mobile={'true'}
        disabled={!answered.length || answerLoading}
      >
        <Text>
          {t(`components.${process.env.REACT_APP_THEME}.button.submit`)}
        </Text>
      </StyledButton>
    </Link>
  )
}

export { SubmitButton, SubmitButtonMobile }
