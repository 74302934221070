import styled from 'styled-components'
import { Button } from 'antd'

export const StyledButton = styled(Button)`
  height: 40px;
  width: ${props=> props.mobile ? '100%' : '165px'};
  display: ${props=> props.mobile ? 'none' : 'flex'};
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: ${props=> props.mobile ? 'block': 'none'};
  }
`
export const Text = styled.span``
export const GifImage = styled.img`
  width: 34px;
  height: auto;
`