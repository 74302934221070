import React from 'react'
import { Spin } from 'antd'
import { Screen } from './styles'

const Loading = () => (
  <Screen>
    <Spin size='large' />
  </Screen>
)

export default Loading
