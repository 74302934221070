import { combineReducers } from 'redux'
import userReducer from './users'
import surveyReducer from './surveys'
import answerReducer from './answers'

const reducer = combineReducers({
  // here we will be adding reducers
  user: userReducer,
  survey: surveyReducer,
  answer: answerReducer
  
})

export default reducer
