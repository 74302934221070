import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'answer',
  initialState: {
    answers: {},
    answered: [],
    answerLoading: false,
    unanswered: false,
  },
  reducers: {
    setAnswers: (state, action) => {
      state.answers = action.payload
    },
    setAnswered: (state, action) => {
      state.answered = action.payload
    },
    setAnswerLoading: (state, action) => {
      state.answerLoading = action.payload
    },
    setUnanswered: (state, action) => {
      state.unanswered = action.payload
    }
  }
})

export const { setAnswers, setAnswered, setAnswerLoading, setUnanswered } = slice.actions

export default slice.reducer
