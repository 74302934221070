import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Theme from '@/containers/Theme'
import './App.less'
import './i18n'
import { getUserIP } from '@/apis/cloudfare'

import Loading from '@/components/Loading'
import { InValidSurvey, BuildingPage } from '@/components/Error/404'

import RedirectToSurvey from '@/components/RedirectToSurvey'

const Welcome = lazy(() => import('@/pages/Welcome'))
const Products = lazy(() => import('@/pages/Products'))
const PainPoints = lazy(() => import('@/pages/PainPoints'))
const Questions = lazy(() => import('@/pages/PainPointQuestions'))
const Review = lazy(() => import('@/pages/ReviewSurvey'))

const App = () => {

  useEffect(() => {
    getUserIP()
  }, [])

  return (
    <Router>
      <Theme>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path='/share/:uniqueName'  component={RedirectToSurvey}/>
            <Route exact path='/survey/:surveyId'  component={Welcome} />
            <Route exact path='/survey/:surveyId/product' component={Products} />
            <Route exact path='/survey/:surveyId/product/:productName/painpoint' component={PainPoints} /> 
            <Route path='/survey/:surveyId/product/:productName/painpoint/:painPointName/question/:questionId' component={Questions} />
            <Route path='/survey/:surveyId/review' component={Review} />
            <Route path='/survey/:surveyId/reviews' component={BuildingPage} />
            <Route path='*' component={InValidSurvey} />
          </Switch>
        </Suspense>
      </Theme>
    </Router>
  )
}

export default App
