import React from 'react'
import { Container, StyledColumn } from './styles'

import image1 from '@/assets/images/landingPage/Img1.png'
import image2 from '@/assets/images/landingPage/Img2.png'
import image3 from '@/assets/images/landingPage/Img3.png'
import image4 from '@/assets/images/landingPage/Img4.png'
import image5 from '@/assets/images/landingPage/Img5.png'
import image6 from '@/assets/images/landingPage/Img6.png'
import image7 from '@/assets/images/landingPage/Img7.png'
import image8 from '@/assets/images/landingPage/Img8.png'

const ImageGroup = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8
  ]

  return (
    <Container>
      {images.map((image, index) => (
        <StyledColumn image={image} key={index}></StyledColumn>
      ))}
    </Container>
  )
}
export default ImageGroup
