import { GET } from '@/utils/axios'

export const getUserIP = () => {
  GET('https://www.cloudflare.com/cdn-cgi/trace').then(({ data }) => {
    window.cloudfareData = data
      .trim()
      .split('\n')
      .reduce(function (obj, pair) {
        pair = pair.split('=')
        return ((obj[pair[0]] = pair[1]), obj)
      }, {})
  })
}
