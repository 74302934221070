import React from 'react'
import { StyledButton, Icon } from './styles'
import { CloseOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

const CancelButton = ({ mobile = 'false' }) => {
  const { surveyId } = useParams()
  return (
    <StyledButton to={`/survey/${surveyId}`} mobile={mobile}>
      <Icon>
        <CloseOutlined />
      </Icon>
    </StyledButton>
  )
}
export default CancelButton
