import styled from 'styled-components'
import { rgba } from 'polished'

export const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${props => rgba(props.theme.WHITE, 0.5)};

  .ant-spin .ant-spin-dot .ant-spin-dot-item {
    background-color: ${props => props.theme.PRIMARY_COLOR} !important;
  }
`
