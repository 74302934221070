import { GET, POST } from '@/utils/axios'

export const getSurvey = async criteria => await POST('/survey', criteria)

export const surveyLogin = async formData =>
  await POST('/survey-login', formData)

export const surveyReject = async enrollmentId =>
  await GET(`/enrollment/${enrollmentId}/reject`)

export const getTasterPdfLink = async formData =>
  await POST(`/tasterPdf`, formData)
