import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'survey',
  initialState: {
    enrollment: null,
    brandProductQuestionObject: null,
    survey: null,
    answeredBrandProductQuestionObject: null
  },
  reducers: {
    setEnrollment: (state, action) => {
      state.enrollment = action.payload
    },
    setBrandProductQuestionObject: (state, action) => {
      state.brandProductQuestionObject = action.payload
    },
    setAnsweredBrandProductQuestionObject: (state, action) => {
      state.answeredBrandProductQuestionObject = action.payload
    }
  }
})

export const {
  setEnrollment,
  setBrandProductQuestionObject,
  setAnsweredBrandProductQuestionObject
} = slice.actions

export default slice.reducer
