import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles'

export const APP_THEME = {
  bunge: {
    APP_TITLE: 'Flavorwiki',
    ICON_URL: 'https://tools.flavorwiki.com/default.png',
    FULL_LOGO_URL:
      'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
    PRIMARY_COLOR: '#90CBE5',
    PRIMARY_TEXT_COLOR: '#7ECDE9',
    PRIMARY_BUTTON_COLOR: '#183B57',
    WHITE: '#ffffff',
    GRAY_TEXT: '#A9A5A5',
    SIDE_BAR_GRAY: '#F7F7FC',
    BLUE_TEXT: '#183B58',
    HEADER_BORDER_COLOR: '#E5EAF4',
    SIDER_DEFAULT_BG: '#f7f7fc',
    BUNGE_GREEN: '#A4C654',
    DEFAULT_TEXT: '#183B57',
    DARK: '#000000',
    DARK_TEXT: '#2D2D2D',
    PARAGRAPH_TEXT: '#272D4E',
    RED: '#EB5757'
  },
  flavorwiki: {
    APP_TITLE: 'Flavorwiki',
    ICON_URL: 'https://tools.flavorwiki.com/default.png',
    FULL_LOGO_URL:
      'https://taster-flavorwiki.s3.eu-central-1.amazonaws.com/FlavorWiki_Full_Logo.png',
    PRIMARY_COLOR: '#A4C654',
    PRIMARY_TEXT_COLOR: '#A4C654',
    PRIMARY_BUTTON_COLOR: '#A4C654',
    WHITE: '#ffffff',
    GRAY_TEXT: '#A9A5A5',
    SIDE_BAR_GRAY: '#F7F7FC',
    BLUE_TEXT: 'rgba(0,0,0,0.85);',
    HEADER_BORDER_COLOR: '#E5EAF4',
    SIDER_DEFAULT_BG: '#f7f7fc',
    BUNGE_GREEN: '#A4C654',
    DEFAULT_TEXT: 'rgba(0,0,0,0.85);',
    DARK: '#000000',
    DARK_TEXT: '#2D2D2D',
    PARAGRAPH_TEXT: '#272D4E',
    RED: '#EB5757'
  }
}

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={APP_THEME[`${process.env.REACT_APP_THEME}`]}>
      {children}
      <GlobalStyle theme={APP_THEME[`${process.env.REACT_APP_THEME}`]} />
    </ThemeProvider>
  )
}

export default Theme
