import styled from 'styled-components'
import { rgba } from 'polished'

export const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => rgba(props.theme.WHITE, 0.5)};
  color: ${props => props.theme.PRIMARY_COLOR}
`
