import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledButton = styled(Link)`
  cursor: pointer;
  display: ${props => (props.mobile === 'true' ? 'none' : 'block')};

  &:hover span {
    border: 1px solid ${props => props.theme.HEADER_BORDER_COLOR};
  }

  @media all and (max-width: 600px) {
    display: ${props => (props.mobile === 'true' ? 'block' : 'none')};
  }
`
export const Icon = styled.span`
  background-color: #F7F7FC;
  height: auto;
  padding: 10px;
  border-radius: 0.4rem;
  color: ${props => props.theme.DARK};
}
`
