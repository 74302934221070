import React from 'react'
import { StyledButton, Text, Icon } from './styles'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const BackButton = ({ showPopup }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { unanswered } = useSelector(state => state.answer)

  return history.location.pathname.includes('review') ||
    !history.location.pathname.includes('painpoint') ? null : (
    <StyledButton
      onClick={() => {
        if (unanswered) {
          showPopup(true)
        } else {
          if (!history.location.pathname.includes('painpoint')) {
            history.goBack()
          } else {
            history.goBack()
          }
        }
      }}
    >
      <Icon>
        <ArrowLeftOutlined />
      </Icon>
      <Text>{t(`components.${process.env.REACT_APP_THEME}.button.back`)}</Text>
    </StyledButton>
  )
}
export default BackButton
