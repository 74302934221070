import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { Screen } from './styles'
import { getSurvey } from '@/apis/surveyAPI'
import { useHistory } from 'react-router-dom'

const Loading = (...props) => {
  const history = useHistory()
  const uniqueName =
    props &&
    props[0] &&
    props[0].match &&
    props[0].match.params &&
    props[0].match.params.uniqueName &&
    props[0].match.params.uniqueName

  useEffect(() => {
    getSurvey({
      criteria: { uniqueName }
    }).then(({ data }) => {
      if (!data) {
        history.replace(`/`)
      } else {
        const surveyId = window.mongoId(data.survey)

        if (!surveyId) {
          history.replace(`/`)
        } else {
          history.replace(`/survey/${surveyId}`)
        }
      }
    })
  }, [uniqueName, history])

  return (
    <Screen>
      <Spin size='large' />
    </Screen>
  )
}

export default Loading
